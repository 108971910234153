import React from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import moment from 'moment'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer'

// Hooks
import { useGlobal, useHistory, useRef, useState } from '../../../../hooks'

// Components
import UIButton from '../../../UI/Button'
import UIFormSeparation from '../../../UI/FormReload/Separation'

import config from '../../../../config'

const { AWS } = config

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: 6,
  },
  header: {},
  flexRow: {
    flex: 1,
    flexDirection: 'row',
  },
  footer: {
    paddingHorizontal: 20,
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  isoLogo: {
    position: 'absolute',
    bottom: 0,
    left: 15,
  },
  separator: {
    width: '100%',
    backgroundColor: '#364f6b',
    paddingVertical: 0.5,
    marginVertical: 2,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    // borderWidth: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
  },
  tableRow: { margin: 'auto', flexDirection: 'row' },
  tableCol: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCell: {
    // marginRight: 'auto',
    // marginLeft: 3,
    // marginTop: 1.5,
    // marginBottom: 1.5,
  },
  frontText: {
    position: 'absolute',
    left: '290px',
    marginHorizontal: 'auto',
    textAlign: 'left',
    justifyContent: 'center',
  },
})

const ReportPDF = ({ id, info, entries }) => {
  const [{ me }] = useGlobal()

  const history = useHistory()

  const Header = () => (
    <View fixed style={styles.header}>
      {/* Client info ------------------ */}
      <View style={styles.flexRow}>
        <View
          style={{
            width: '38%',
          }}
        >
          {me.laboratory === 'centinela' ? (
            <Image
              src={'/imgs/logo/report/centinela.png'}
              style={{ width: 100 }}
            />
          ) : (
            <Image src={'/imgs/logo/coddi-dark.png'} style={{ width: 100 }} />
          )}
        </View>
        <View
          style={{
            width: '8%',
            fontWeight: 'bold',
          }}
        >
          {/*
          <Text>Cliente</Text>
          */}
          <Text>Faena</Text>
          <Text>Equipo</Text>
          <Text>Tipo de equipo</Text>
          <Text>Técnica</Text>
          <Text>Inspector</Text>
        </View>
        <View
          style={{
            width: '34%',
          }}
        >
          {/*
          <Text>{info.client}</Text>
          */}
          <Text>{info.site}</Text>
          <Text>{info.machine}</Text>
          <Text>{info.machine_type}</Text>
          <Text>{info.technique}</Text>
          <Text>{info.inspector}</Text>
        </View>
        <View
          style={{
            width: '20%',
            textAlign: 'right',
            fontWeight: 'bold',
          }}
        >
          <Text>Informe de Inspecciones Nº {id}</Text>
          <Text>Fecha {moment().format('DD-MM-YYYY')}</Text>
        </View>
      </View>
      <View style={styles.separator} />
    </View>
  )

  const Footer = () => (
    <View fixed style={styles.footer}>
      <View style={styles.separator} />
      <Text>CODDI S.A.</Text>
      <Text>
        José Francisco Blumell 215, Antofagasta - Chile, https://app.coddi.ai
      </Text>
      <Text style={{ paddingTop: 5, paddingLeft: 30 }}>
        No se permite al solicitante reproducir en ninguna forma el logo, nombre
        o marca registrada de CODDI, salvo que exista una autorización previa y
        por escrito de CODDI
      </Text>
      {/*<View fixed style={styles.isoLogo}>
        <Image src={`${imageApplusLogo}`} style={{ width: '30px' }} />
      </View>*/}
    </View>
  )

  const Entries = () => {
    return (
      <>
        {entries.map((entry, index) => {
          const images_per_row = 3

          const photos = entry.photos.reduce((p, c, i) => {
            const global_index = Math.floor(i / images_per_row)
            const local_index = i % images_per_row
            if (local_index === 0) p[global_index] = []
            p[global_index][local_index] = c
            return p
          }, [])

          return (
            <View break={index > 0} wrap={false}>
              <View style={styles.table}>
                <View
                  style={[
                    styles.tableRow,
                    { height: '20px', backgroundColor: '#F5F5DC' },
                  ]}
                >
                  <View style={[styles.tableCol, { width: '25%' }]}>
                    <Text style={[styles.tableCell, { margin: 'auto' }]}>
                      Sistema
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '25%' }]}>
                    <Text style={[styles.tableCell, { margin: 'auto' }]}>
                      Subsistema
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '15%' }]}>
                    <Text style={[styles.tableCell, { margin: 'auto' }]}>
                      Criticidad
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '35%' }]}>
                    <Text style={[styles.tableCell, { margin: 'auto' }]}>
                      AV/OT
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow} key={`essay_${index}`}>
                  <View style={[styles.tableCol, { width: '25%' }]}>
                    <Text style={styles.tableCell}>{entry.system}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '25%' }]}>
                    <Text style={styles.tableCell}>{entry.sub_system}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '15%' }]}>
                    <Text style={[styles.tableCell, { margin: 'auto' }]}>
                      {entry.criticality}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '35%' }]}>
                    <Text style={[styles.tableCell, { margin: 'auto' }]}>
                      {entry.avots.av.length > 0 &&
                        entry.avots.av.map((av) => 'AV ' + av).join(', ')}
                      {entry.avots.ot.length > 0 &&
                        entry.avots.ot.map((ot) => 'OT ' + ot).join(', ')}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View
                    style={[
                      styles.tableCol,
                      { minHeight: '50px', width: '100%' },
                    ]}
                  >
                    <Text style={styles.tableCell}>{entry.observation}</Text>
                  </View>
                </View>
                {entry.photos.length > 0 && (
                  <>
                    {photos.map((photo_list) => (
                      <View
                        style={[
                          styles.tableRow,
                          { backgroundColor: '#F5F5DC' },
                        ]}
                      >
                        {photo_list.map((photo) => {
                          return (
                            <View
                              style={[
                                styles.tableCol,
                                {
                                  width: 100 / images_per_row + '%',
                                },
                              ]}
                            >
                              {/*<Text style={styles.tableCell}>{photo}</Text>*/}
                              <Image
                                style={{
                                  objectFit: 'contain',
                                  padding: 2,
                                  marginRight: 'auto',
                                }}
                                src={{
                                  uri: `https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${me.laboratory}/inspections/photos/${id}/${photo}`,
                                  method: 'GET',
                                  body: '',
                                  headers: '',
                                }}
                              />
                            </View>
                          )
                        })}
                      </View>
                    ))}
                  </>
                )}
                {/*entry.files.map((file) => (
                <Page
                  file={`https://s3-sa-east-1.amazonaws.com/${AWS.BUCKET}/${me.laboratory}/inspections/files/${id}/${file}`}
                />
              ))*/}
              </View>
            </View>
          )
        })}
      </>
    )
  }

  const Report = () => {
    const d = new Date()
    const month = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Augosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]

    return (
      <Document title={`Información de Inspecciones`} author={`CODDI`}>
        {me.laboratory === 'centinela' && (
          <Page size="LETTER" style={{ ...styles.page }}>
            <Image src={'/imgs/inspections/centinela_front.png'} />
            <Text
              style={{
                top: '400px',
                fontFamily: 'Roboto',
                fontSize: 19,
                color: '#00778A',
                ...styles.frontText,
              }}
            >
              {`Informe de Inspecciones
${info.machine}
_______________________`.toUpperCase()}
            </Text>
            <Text
              style={{
                top: '480px',
                fontSize: 12,
                fontWeight: 'ultralight',
                color: '#878A8D',
                ...styles.frontText,
              }}
            >
              {`Superintendencia de Confiablidad y
Mejoramiento Minas

Gerencia Mantenimiento`}
            </Text>
            <Text
              style={{
                top: '550px',
                fontFamily: 'Roboto',
                fontSize: 19,
                color: '#00778A',
                ...styles.frontText,
              }}
            >
              {`_______________________
${month[d.getMonth()].toUpperCase()}`}
            </Text>
            <Text
              style={{
                top: '600px',
                fontFamily: 'Roboto',
                fontSize: 30,
                fontWeight: 'normal',
                color: '#878A8D',
                ...styles.frontText,
              }}
            >
              2024
            </Text>
          </Page>
        )}
        <Page size="LETTER" style={styles.page}>
          <Header />
          <View style={{ marginBottom: '30px' }}>
            <Entries />
          </View>
          <Footer />
        </Page>
      </Document>
    )
  }

  const handleBack = () => {
    history.push(`/inspections/report`)
  }

  return (
    <Row>
      <Col xs={12}>
        <Form.Group as={Row} className="m-1">
          <Col className="d-flex">
            {me.id_role !== 5 && (
              <UIButton onClick={handleBack} className="mr-2">
                Volver a la lista
              </UIButton>
            )}
            {/*<PDFDownloadLink document={<Report />} fileName={fileName}>
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <UIButton disable variant="secondary">
                        Creando PDF ...
                      </UIButton>
                    ) : (
                      <UIButton>Descargar PDF</UIButton>
                    )
                  }
                      </PDFDownloadLink>*/}
          </Col>
        </Form.Group>
        <UIFormSeparation />
      </Col>
      <Col xs={12}>
        <PDFViewer style={{ width: '100%', height: '700px' }}>
          <Report />
        </PDFViewer>
      </Col>
    </Row>
  )
}

export default ReportPDF
